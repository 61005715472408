import React from 'react'
import Input from '../../Input/Input'
import Checkbox from '../../Checkbox/Checkbox'
import Screen from '../../Screen/Screen'
import style from './FirstScreen.module.css'
import Button from '../../Button/Button'
import PolicyFooter from '../../PolicyFooter/PolicyFooter'
import FormValidator from "../../../FormValidator"
import AgePopup from "../../AgePopup/AgePopup"
import Storage from "../../../storage"
import { withRouter } from 'react-router-dom'
import { searchUser } from '../../../QueryObjects'

class FirstScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passport: '',
      firstName: '',
      lastName: '',
      checkbox1: false,
      checkbox2: false,
      errors: {
        passport: '',
        firstName: '',
        lastName: '',
        checkbox1: '',
        checkbox2: ''
      },
      loading: false
    }
    this.popupRef = React.createRef();
    this.formRef = React.createRef();
    this.history = props.history;
    this.params = new URLSearchParams(props.location.search);
  }

  // componentDidMount() {
  //   if ( this.params.get('host_ref') ) {
  //     Storage.set('referrerCode', this.params.get('host_ref'));
  //   } else if ( window.location.pathname === '/sms' ) {
  //     Storage.set('referrerCode', 'sms')
  //   } else if ( window.location.pathname === '/hostesses' ) {
  //     Storage.set('referrerCode', 'hostesses')
  //   } else if ( window.location.pathname === '/email' ) {
  //     Storage.set('referrerCode', 'email')
  //   } else if ( window.location.pathname === '/' || window.location.pathname === '/share' ) {
  //     Storage.set('referrerCode', "");
  //   }
  // }

  handleChange (e) {
    const {target} = e;
    const {name, value, type} = target;

    this.setState({[name]: type === 'checkbox' ? e.currentTarget.checked : value}, () => {
      this.validateFields([target]);
    });
  }

  validateFields (elements = null ) {
    [...elements].forEach( element => {
      const {name, value, type} = element;

      if ( type === 'submit' ) return;

      const formValidator = new FormValidator();
      const errors = this.state.errors;

      if ( type === 'checkbox' ) {
        (!element.checked) ? (errors[name] = 'שדה זה הוא חובה') : (errors[name] = '');
        this.setState({errors});
        return;
      }

      // TODO: remove hardcoded number and move length check to form validator
      if ( name === 'passport' && value.length > 9 ) {
        errors[name] = 'ניתן להזין רק 9 מספרים';
        this.setState({errors, [name]: value});
        return;
      }

      if ( value.length === 0 ) {
        errors[name] = formValidator.errorMessages.empty;
        this.setState({errors, [name]: value});
        return;
      }

      if ( value.match(formValidator.regexp.html) ) {
        errors[name] = formValidator.errorMessages.invalid;
        this.setState({errors, [name]: value});
        return;
      }

      if ( type === 'text' && !formValidator.validate(value, 'onlyHebrew') ) {
        errors[name] = formValidator.errorMessages.onlyHebrew;
        this.setState({errors, [name]: value});
        return;
      }

      errors[name] = '';
      this.setState({errors});
    } )
  }

  isFormValid () {
    let valid = true;

    Object.values(this.state.errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );

    [this.state.passport, this.state.firstName, this.state.lastName].forEach( value => value.length === 0 && (valid = false) );

    [this.state.checkbox1, this.state.checkbox2].forEach( checked => !checked && (valid = false) );

    return valid;
  }

  handleAgeCheckboxClick (e) {
    e.preventDefault();
    this.popupRef.current.open();
  }

  handleAgeConfirm () {
    this.setState({checkbox2: true}, () => this.validateFields([this.formRef.current.elements['checkbox2']]));
    this.popupRef.current.close();
  }

  async handleSubmit (e) {
    e.preventDefault();
    let errors = {};

    this.validateFields([...e.currentTarget.elements]);
    if ( !this.isFormValid() ) return;

    this.setState({loading: true});

    let fd = new FormData(e.target);
    let user = await searchUser( fd.get('passport'), fd.get('firstName'), fd.get('lastName'), Storage.user.referrerCode );

    this.setState({loading: false});

    if ( user.success === 'false' ) {
      errors['passport'] = user.errorMessage;
      this.setState({errors});
      return;
    }

    localStorage.clear();

    fd.set('numberOfTimesParticipated', user.numberOfTimesParticipated);
    fd.set('referrerSFID', user.referrerSFID);
    fd.set('userFound', user.userFound);
    fd.set('userSFID', user.userSFID);
    fd.set('userSFID_ForMember', user.userSFID_ForMember);
    fd.set('userType', user.userType);
    fd.set('GGID_VerifiedBy', user.GGID_VerifiedBy);
    fd.set('highScore',0);
    fd.set('memberSFID', user.memberSFID);
    fd.set('userType', user.userType);
    fd.set('email', '');
    fd.set('phone', '');
    fd.set('birthDate', '');
    fd.set('gender', '');
    fd.set('brand', '');
    fd.set('score', '');
    Storage.write( fd );

    this.history.push('/gamestart');
  }

  handleNumberInput () {
    this.setState({passport: this.state.passport.substring(0, 9)})
  }

  render () {
    let {referrerCode} = Storage.user;

    if ( typeof referrerCode !== 'undefined' && (referrerCode === 'hostesses' || referrerCode.match(/group/i) !== null) ) {
      return (
          <Screen loadingAnimation={this.state.loading} name="first" background={this.props.background} backgroundTablet={this.props.backgroundTablet} backgroundDesktop={this.props.backgroundDesktop}>
            <AgePopup ref={this.popupRef} onConfirm={() => this.handleAgeConfirm()} />
            <h1 className={style.title}>בואו נתחיל בפעילות</h1>

            <p className={style.subtitle}>אבל שניה לפני... עלינו לוודא שאתם מעשנים בני 21 ומעלה, זאת על מנת לקבל מידע נוסף מפיליפ מוריס בע"מ לגבי מוצריה.
            </p>

            <form ref={this.formRef} onSubmit={e => this.handleSubmit(e)} className={style.form}>
              <Input pattern="\d*" type="number" onInput={e => this.handleNumberInput(e)} onChange={e => this.handleChange(e)} value={this.state.passport} name="passport" placeholder="תעודת זהות" errorMessage={this.state.errors.passport} required maxLength={9} />
              <Input onChange={e => this.handleChange(e)} value={this.state.firstName} name="firstName" placeholder="שם פרטי" errorMessage={this.state.errors.firstName} required />
              <Input onChange={e => this.handleChange(e)} value={this.state.lastName} name="lastName" placeholder="שם משפחה" errorMessage={this.state.errors.lastName} required />
              <Checkbox errorMessage={this.state.errors.checkbox1} onChange={e => this.handleChange(e)} name="checkbox1" checked={this.state.checkbox1} text="אני מאשר/ת שאני בגיר/ה ומעל גיל 21" />
              <Checkbox errorMessage={this.state.errors.checkbox2} onClick={(e) => this.handleAgeCheckboxClick(e)} name="checkbox2" checked={this.state.checkbox2} text="אני מבקש\ת לקבל פרסומת למוצרי עישון מפיליפ מוריס בע'מ ומאשר\ת את כתב בקשת מידע והסכמה ומדיניות הפרטיות" />

              <Button type="submit" className={style.submitButton}>אני מאשר/ת</Button>
            </form>

            <PolicyFooter />
          </Screen>
      )
    }else{
      return (
          <Screen loadingAnimation={this.state.loading} name="first" background={this.props.background} backgroundTablet={this.props.backgroundTablet} backgroundDesktop={this.props.backgroundDesktop}>
            <AgePopup ref={this.popupRef} onConfirm={() => this.handleAgeConfirm()} />
            <h1 className={style.title}>בואו נתחיל בפעילות</h1>

            <p className={style.subtitle}>אבל שניה לפני עלינו לוודא שאתם מעשנים בני 21 ומעלה, וזאת על מנת לקבל מידע נוסף מפיליפ מוריס בע״מ לגבי מוצריה.</p>

            <form ref={this.formRef} onSubmit={e => this.handleSubmit(e)} className={style.form}>
              <Input pattern="\d*" type="number" onInput={e => this.handleNumberInput(e)} onChange={e => this.handleChange(e)} value={this.state.passport} name="passport" placeholder="תעודת זהות" errorMessage={this.state.errors.passport} required maxLength={9} />
              <Input onChange={e => this.handleChange(e)} value={this.state.firstName} name="firstName" placeholder="שם פרטי" errorMessage={this.state.errors.firstName} required />
              <Input onChange={e => this.handleChange(e)} value={this.state.lastName} name="lastName" placeholder="שם משפחה" errorMessage={this.state.errors.lastName} required />
              <Checkbox errorMessage={this.state.errors.checkbox1} onChange={e => this.handleChange(e)} name="checkbox1" checked={this.state.checkbox1} text="אני מאשר/ת שאני בגיר/ה ומעל גיל 21" />
              <Checkbox errorMessage={this.state.errors.checkbox2} onClick={(e) => this.handleAgeCheckboxClick(e)} name="checkbox2" checked={this.state.checkbox2} text="אני מבקש\ת לקבל פרסומת למוצרי עישון מפיליפ מוריס בע'מ ומאשר\ת את כתב בקשת מידע והסכמה ומדיניות הפרטיות" />

              <Button type="submit" className={style.submitButton}>אני מאשר/ת</Button>
            </form>

            <PolicyFooter />
          </Screen>
      )
    }



  }
}

export default withRouter( FirstScreen );
