import React, { useState, useEffect } from 'react'
import style from './Clicker.module.css'
import cigaretteImg from '../../assets/images/cigarette-vertical.png'
import bubbleImg from '../../assets/images/ball.png'

function Clicker({ className, onCountUpdate, onClick }) {
  const [count, setCount] = useState(0);

  let styles = {
    fontFamily: count === 0 ? 'gad' : 'orbitron'
  }

  useEffect(() => {
    if (onCountUpdate) onCountUpdate(count);
  })

  function handleClick() {
    setCount(count + 1);
    if (onClick) onClick();
  }

  return (
    <div className={[style.clickerContainer, className].join(' ')}>
      <div className={style.ballContainer}>
        <div onClick={handleClick} className={style.ball}>
          <span className={style.text} style={styles}>{count === 0 ? 'לחצו' : count}</span>
          <img className={style.bubbleImage} src={bubbleImg} alt="capsule" />
        </div>
      </div>
      <img className={style.cigaretteImage} src={cigaretteImg} alt="capsule" />
    </div>
  )
}

export default Clicker
