import React from 'react'
import style from './Footer.module.css'

function Footer() {
  let text = window.innerWidth < 1024
    ? 'אזהרה -'
    : 'אזהרה:';

  return (
    <div className={style.footer}>
      <h2 className={style.footerTitle}>{text}</h2>
      <p>מחקרים רפואיים קובעים כי ילדים להורים מעשנים סובלים יותר מאסטמה</p>
    </div>
  )
}

export default Footer
