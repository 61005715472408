import style from './Input.module.css'

function Input({ children, placeholder, required, errorMessage, className, ...props }) {
  return (
    <label className={style.label}>
      {children}
      <input
        {...props}
        className={`${style.input} ${className}`}
        placeholder={(required ? '*' : '') + placeholder} />
      <p className={style.errorMessage}>{errorMessage}</p>
    </label>
  )
}

Input.defaultProps = {
  type: 'text',
  errorMessage: '',
  name: '',
  placeholder: '',
  className: ''
}

export default Input
