import React from "react";
import Popup from "../Popup/Popup";
import style from "./GameScorePopup.module.css";

function GameScorePopup ({opened}, ref ) {
  return (
    <Popup opened={opened} ref={ref}>
      <div className={style.container}>
        <div className={style.dashedBox}>
          <h3 className={style.title}>נגמר הזמן</h3>
        </div>
      </div>
    </Popup>
  )
}

export default React.forwardRef(GameScorePopup);