import React from 'react'
import Button from '../Button/Button'
import shareIcon from '../../assets/images/share-icon.svg'
import style from './ShareButton.module.css'

const ShareButton = ({ children, onShareComplete }) => {
  async function handleClick() {
    const shareData = {
      title: 'MRL Capsule',
      text: 'היי, זכור לך שביקשת ממני מידע בכתב על הסיגריה החדשה במימד חדש של טעם? זאת ההזדמנות שלך לנסות ולזכות בחוויה אקסקלוסיבית באילת! 🧨 קליק לפרטים והשתתפות >>',
      url: 'https://tinyurl.com/usvk77pf'
    };

    await navigator.share(shareData);
    onShareComplete();
  }

  return (
    <Button bounceAnimation={false} onClick={handleClick}>
      {children}
      <i className={style.icon}><img alt="share icon" src={shareIcon} /></i>
    </Button>
  )
}

export default ShareButton
