export default class FormValidator {
  constructor() {
    this.errorMessages = {
      empty: '*זהו שדה חובה',
      invalid: 'הערך שהוזן אינו חוקי',
      onlyHebrew: 'שדה זה יכול להכיל רק עברית'
    }

    this.regexp = {
      html: /(<.*>.*<\/.*>)|(<.*\/>)|(<.*>)/gi,
      phone: /^\+?\d{1,5}\d{7}$/i,
      email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
      onlyHebrew: /[\u0590-\u05FF]/
    }
  }

  validate (value, pattern) {
    if ( !this.regexp.hasOwnProperty(pattern) ) {
      console.error(`No such pattern as ${pattern} \n Use one of this: ${Object.keys(this.regexp).join(', ')}`);
      return;
    }

    return value.match(this.regexp[pattern]);
  }
}