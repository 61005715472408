import React, { useState, useRef, useEffect } from 'react'
import Screen from '../../Screen/Screen'
import style from './GameScreen.module.css'
import Clicker from '../../Clicker/Clicker'
import GameCountdown from '../../GameCountdown/GameCountdown'
import BubblesCanvas from '../../BubblesCanvas/BubblesCanvas'
import Storage from "../../../storage"
import GameScorePopup from "../../GameScorePopup/GameScorePopup"
import {useHistory} from "react-router-dom"
import {updateStatus} from "../../../QueryObjects";

function GameScreen({ background, backgroundTablet, backgroundDesktop }) {
  const [currentScore, setCurrentScore] = useState(0);
  const [isLoading, setIsLoading] = useState(0);
  const history = useHistory();
  const timerRef = useRef();
  const canvasRef = useRef();
  const popupRef = useRef();

  useEffect(() => {
    setIsLoading(true);
    async function updateStartedGame(){

      let staredGame = await updateStatus(Storage.user, '4- Started Game');

      if( staredGame.success === 'true' || staredGame.success === true )
        setIsLoading(false);
      else
        window.location.href('/share')

    }

    if( Storage.user.joinedVia == 'sms' || Storage.user.joinedVia == 'email' )
      updateStartedGame();
    else
      setIsLoading(false);

  }, []);

  function handleCountUpdate(count) {
    if (count === 1) {
      timerRef.current.start();
    }
    setCurrentScore(count);
  }

  function handleComplete() {
    Storage.set('score', currentScore);
    let hightScore = typeof Storage.user.highScore === 'undefined' ? 0 : Storage.user.highScore;
    if(hightScore < currentScore) {
        Storage.set('highScore', currentScore)
    }
    popupRef.current.open();
    setTimeout(() => history.push('/endform'), 2000);
  }

  function handleClick() {
    if (currentScore >= 100) return;

    canvasRef.current.drawRandomCircle();
  }

  return (
    <Screen loadingAnimation={isLoading} className={style.screen} background={background} backgroundTablet={backgroundTablet} backgroundDesktop={backgroundDesktop}>
      <BubblesCanvas ref={canvasRef} />
      <GameCountdown
        onComplete={handleComplete}
        ref={timerRef} />
      <p className={style.statusText}>השיא שלך: <span>{currentScore}</span></p>
      <p className={style.statusText}> שיא אחרון : <span> {Storage.user.highScore ?? '0'} </span> </p>
      <Clicker onClick={handleClick} onCountUpdate={handleCountUpdate} className={style.clicker} />

      <GameScorePopup ref={popupRef} />
    </Screen>
  )
}

export default GameScreen
