import React, {useRef, useState} from "react";
import Input from "../Input/Input";
import style from './Date.module.css'

function Date({onChange, name, placeholder, required, value = "1998-01-01", errorMessage}) {
  const [date, setDate] = useState(value);
  const dateRef = useRef();
  let textPlaceholder = `${required ? `*${placeholder}` : placeholder} ${date}`;

  function handleChange(e) {
    setDate(e.currentTarget.value);
    onChange(e);
  }

  function handleFocus() {
    dateRef.current.focus();
  }

  return (
    <Input onFocus={handleFocus} type="text" readOnly value={textPlaceholder} placeholder={textPlaceholder} errorMessage={errorMessage}>
      <input
        ref={dateRef}
        name={name}
        className={style.realDateInput}
        onChange={handleChange}
        value={date}
        type="date"/>
    </Input>
  )
}

export default Date;
