import React from 'react'
import style from './Screen.module.css'
import Footer from '../Footer/Footer'
import {motion} from "framer-motion";

function Screen({ background, backgroundTablet, backgroundDesktop, children, name, wrapperMaxWidth, loadingAnimation, className }) {
  let backgroundImg = '';

  if (window.innerWidth >= 1024 && window.innerWidth < 1366) {
    backgroundImg = <img className={style.background} src={backgroundTablet} alt={`${name} screen background`} />
  } else if (window.innerWidth >= 1366) {
    backgroundImg = <img className={style.background} src={backgroundDesktop} alt={`${name} screen background`} />
  } else {
    backgroundImg = <img className={style.background} src={background} alt={`${name} screen background`} />
  }

  let classes = `${style.screen} ${className}`;

  if (name) {
    classes += ` screen-${name}`;
  }

  if ( loadingAnimation ) {
    classes += ` ${style.isLoading}`;
  }

  return (
    <motion.div className={classes} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
      {backgroundImg}
      <div className={style.screenWrapper} style={{ maxWidth: wrapperMaxWidth }}>
        <div className={style.screenInner}>
          {children}
        </div>
      </div>

      <Footer />
    </motion.div>
  )
}

export default Screen
