import React from 'react'
import style from './BubblesCanvas.module.css'
import bubbleSrc from '../../assets/images/bubble.png'

export default class BubblesCanvas extends React.Component {

  constructor(props) {
    super(props);
    this.bubbleImg = new Image();
    this.bubbleImg.src = bubbleSrc;
    this.canvasRef = React.createRef();
    this.ctx = '';
  }

  componentDidMount() {
    this.ctx = this.canvasRef.current.getContext('2d')
  }

  getRandomCoord() {
    return {
      x: Math.floor(Math.random() * this.canvasRef.current.width),
      y: Math.floor(Math.random() * this.canvasRef.current.height)
    }
  }

  drawRandomCircle() {
    let coord = this.getRandomCoord();
    this.ctx.drawImage(this.bubbleImg, coord.x, coord.y);
  }

  render() {
    return (
      <canvas
        ref={this.canvasRef}
        className={style.canvas}
        width={window.innerWidth}
        height={window.innerHeight}>
      </canvas>
    )
  }
}
