import React from 'react'
import style from './PolicyFooter.module.css'
import pdflink from '../../assets/consumers-privacy-notice.pdf'
import pdflinkSec from '../../assets/fusion-takanon.pdf'

const PolicyFooter = ({ className }) => {
  return (
    <div className={[style.footer, className].join(' ')}>
        <a rel="noreferrer" target="_blank" href={pdflinkSec}>תקנון</a>
        <a rel="noreferrer" target="_blank" href={pdflink}>מדיניות פרטיות</a>
      <p>האתר מנוהל ע״י פיליפ מוריס בע״מ. הפעילות מיועדת למעשנים מעל גיל 21, תושבי ישראל בלבד</p>
    </div>
  )
}

export default PolicyFooter
