import Countdown, { zeroPad } from 'react-countdown'
import style from './GameCountdown.module.css'
import { useState, forwardRef } from 'react'

const GameCountdown = forwardRef(({ onTick, onComplete, startText = "04:00" }, ref) => {
  const [date, setDate] = useState(Date.now() + (4001));
  const [started, setStarted] = useState(false);

  function handleStart() {
    setStarted(true);
  }

  const renderer = ({ seconds, milliseconds }) => {
    let millisecondsNew = milliseconds.toString().length > 2
      ? milliseconds.toString().slice(0, -1)
      : zeroPad(milliseconds);
    return (
      <span className={style.timerText}>
        {
          (!started)
            ? startText
            : `${zeroPad(seconds)}:${millisecondsNew}`
        }
      </span>
    )
  };

  function handleTick(timeDelta) {
    setDate(Date.now() + timeDelta.total);
    if (onTick) onTick(timeDelta);
  }

  return (
    <Countdown
      ref={ref}
      autoStart={false}
      onTick={handleTick}
      onComplete={onComplete}
      onStart={handleStart}
      renderer={renderer}
      precision={3}
      intervalDelay={0}
      date={date}
    />
  )
})

export default GameCountdown
