import Screen from '../../Screen/Screen'
import Button from '../../Button/Button'
import {Link, withRouter} from 'react-router-dom'
import style from './ThirdScreen.module.css'
// import cigaretteSrc from '../../../assets/images/cigarette-inclined.png'
import {getUserBySID, updateStatus} from '../../../QueryObjects'
import React from "react";
import Storage from "../../../storage";

//function ThirdScreen({ background, backgroundTablet, backgroundDesktop }) {
class ThirdScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    async componentDidMount() {

        if( window.location.pathname.includes('sms') || window.location.pathname.includes('email') ) {
            this.setState({loading: true});

            Storage.clear();
            const sfid = new URLSearchParams(window.location.search).get("SID");

            if ((window.location.pathname.includes('sms') || window.location.pathname.includes('email') ) && (sfid === null || sfid === '')) {
                window.location.href = '/share';
            }

            let resp = await getUserBySID(sfid);
            if (resp.success === true || resp.success === 'true' || resp.errorMessage === 'Participation not allowed – Cannot go back to previous status ') {

                let status_resp = await updateStatus(resp);

                Object.entries(status_resp).forEach(entry => {
                    const [key, value] = entry;
                    Storage.set(key, value);
                });

                if (['/sms/', '/sms', '/email', '/email/'].indexOf(window.location.pathname) !== -1) {
                    Storage.set('joinedVia', window.location.pathname.replaceAll('/', ''));
                }

                this.setState({loading: false});

            } else {
                //window.location.href = '/share';
            }
        }

    }
    
    render () {
        return (
            <Screen name="third" background={this.props.background} backgroundTablet={this.props.backgroundTablet}
                    backgroundDesktop={this.props.backgroundDesktop} loadingAnimation={this.state.loading}>
                {
                    (window.innerWidth < 1024)
                        ? (
                            <>
                                <h1 className={style.title}>FUSION CAPSULE</h1>
                                <p className={style.subtitle}>רוצים לנסות ולזכות <br/><span className="blue-text">בחוויה אקסקלוסיבית</span><br/> בת
                                    3 ימים באילת?</p>
                                <h2 className={style.secondTitle}>לחצו על הקפסולה כמה שיותר פעמים לפני שיישרף לכם הזמן</h2>
                            </>
                        )
                        : (
                            <>
                                <h2 className={style.secondTitle}>רוצים לנסות ולזכות<br/><span className="blue-text"> בחוויה אקסקלוסיבית </span><br/> בת
                                    3 ימים באילת?</h2>
                                <h1 className={style.title}>FUSION CAPSULE</h1>
                                <p className={style.subtitle}>לחצו על הקפסולה כמה שיותר פעמים לפני שיישרף לכם הזמן</p>
                            </>
                        )
                }

                <Link className={style.link} to="/ingame"><Button> קחו אותי לפעילות ></Button></Link>
            </Screen>
        )
    }
}

export default withRouter(ThirdScreen)
