import React, {useState} from 'react'
import style from './Popup.module.css'

// TODO: remove use of ref
const Popup = React.forwardRef(({children, opened}, ref) => {
  const [activeClass, setActiveClass] = useState(opened ? style.isActive : '');

  function close () {
    setActiveClass('');
  }

  function open () {
    setActiveClass(style.isActive);
  }

  React.useImperativeHandle(ref, () => ({
    open,
    close
  }));

  function handleWrapperClick (e) {
    if ( e.target.classList.contains(style.wrapper) ) close();
  }

  return (
    <div onClick={handleWrapperClick} ref={ref} className={`${style.wrapper} ${activeClass}`}>
      <div className={style.modal}>
        {children}
      </div>
    </div>
  )
})

export default Popup