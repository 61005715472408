const Storage = (() => {
  const userKey = 'user';
  let user = (getParsedData)();

  function write ( formData ) {
    for ( const [key, value] of formData.entries() ) {
      if ( !(key in user) || user[key] !== value ) user[key] = value;
    }

    localStorage.setItem(userKey, JSON.stringify(user));
  }

  function setObject ( object ) {
    localStorage.setItem(userKey, JSON.stringify(object));
  }

  function set ( paramName, value ) {
    user[paramName] = value;
    localStorage.setItem(userKey, JSON.stringify(user));
  }

  function getParsedData () {
    let jsonData = JSON.parse( localStorage.getItem(userKey) );
    return jsonData !== null ? jsonData : {};
  }

  function clear() {
    localStorage.setItem(userKey, JSON.stringify({}));
  }

  return {
    write,
    getParsedData,
    set,
    setObject,
    user,
    clear
  }
})();

export default Storage;
