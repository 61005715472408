import React from 'react'
import style from './Button.module.css'

function Button({ children, color, className, bounceAnimation = true, ...props }) {
  return (
    <button
      {...props}
      style={{ background: color }}
      className={`${style.button} ${className} ${bounceAnimation && style.bounceAnimation}`}>{children}</button>
  )
}

export default Button
