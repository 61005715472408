import React from 'react'
import Screen from '../../Screen/Screen'
import style from './ResultScreen.module.css'
import Button from '../../Button/Button'
import ShareButton from '../../ShareButton/ShareButton'
import PolicyFooter from '../../PolicyFooter/PolicyFooter'
import Storage from "../../../storage"
import {updateUser, updateStatus} from '../../../QueryObjects'
import {Link, withRouter} from "react-router-dom"

class ResultScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        shareComplete: false,
        loading: true,
    }
  }

  handleShareComplete () {
    this.setState({shareComplete: true});

      let {referrerCode} = Storage.user;
      if ( referrerCode === 'hostesses' || referrerCode.match(/group/i) !== null ) {
          //this.props.history.push('/hostesses');
      }
      // else if ( ['email'].indexOf( referrerCode ) !== -1 ){
      //     this.props.history.push('/email');
      // }else if ( ['sms'].indexOf( referrerCode ) !== -1 ){
      //     this.props.history.push('/sms');
      // }
      else{
          this.props.history.push('/gamestart');
      }
  }

    updateStatus() {
        updateStatus(Storage.user, '8- IQOS Interesting');
    }

  async componentDidMount() {

      if( !window.alreadySent ) {
          console.log(window.alreadySent);
          this.setState({'loading': true});
          window.alreadySent = true;
          Storage.set('numberOfTimesParticipated', +Storage.user.numberOfTimesParticipated + 1);
          let resp = await updateUser(Storage.user);
          Storage.set('memberSFID', resp.memberSFID)
          window.alreadySent = false;
          this.setState({'loading': false});
      }

      setTimeout(()=> {
          this.setState({'loading': false});
      }, 3000)

  }

  renderButtons () {
    let {referrerCode} = Storage.user;
    let referrerCodeUrl = '/hostesses?host_ref=' +referrerCode
    if ( typeof referrerCode !== 'undefined' && (referrerCode === 'hostesses' || referrerCode.match(/group/i) !== null) ) {
      return <Button><a rel="noreferrer" href={referrerCodeUrl}>רישום מחדש ></a></Button>
    } else if ( ['sms'].indexOf( referrerCode ) !== -1 ) {
      return <>
        {(window.innerWidth <= 768)
          ? <ShareButton onShareComplete={() => this.handleShareComplete()}><a rel="noreferrer" href="/sms">הזמינו חבר ושחקו שוב ></a></ShareButton>
          : <Button><a rel="noreferrer" href="/ingame">שחקו שוב ></a></Button>
        }
        <br />
        <Button color="#4ED2D3"><a target="_blank" rel="noreferrer" href="https://il.iqos.com/he/products/benefits?age_verified=1&utm_source=CC_MRL&utm_medium=EM&utm_campaign=CC_MRL_FUSION_JUL21&utm_content=CC2IQOS" onClick={this.updateStatus}>למה להישרף? תחממו ></a></Button>
      </>
    }else if ( ['email'].indexOf( referrerCode ) !== -1 ) {
        return <>
            {(window.innerWidth <= 768)
                ? <ShareButton onShareComplete={() => this.handleShareComplete()}><a rel="noreferrer" href="/email">הזמינו חבר ושחקו שוב ></a></ShareButton>
                : <Button><a rel="noreferrer" href="/ingame">שחקו שוב ></a></Button>
            }
            <br />
            <Button color="#4ED2D3"><a target="_blank" rel="noreferrer" href="https://il.iqos.com/he/products/benefits?age_verified=1&utm_source=CC_MRL&utm_medium=EM&utm_campaign=CC_MRL_FUSION_JUL21&utm_content=CC2IQOS" onClick={this.updateStatus}>למה להישרף? תחממו ></a></Button>
        </>
    }else if ( ['share'].indexOf( referrerCode ) !== -1 ) {
      return <>
        {(window.innerWidth <= 768)
            ? <ShareButton onShareComplete={() => this.handleShareComplete()}>הזמינו חבר ושחקו שוב ></ShareButton>
            : <Button><Link to="/gamestart">שחקו שוב ></Link></Button>
        }

        <p className={style.restrictionText}>* מעשן בגיר 21+ שביקש לקבל מידע</p>
        <Button color="#4ED2D3"><a target="_blank" rel="noreferrer" href="https://il.iqos.com/he/products/benefits?age_verified=1&utm_source=CC_MRL&utm_medium=EM&utm_campaign=CC_MRL_FUSION_JUL21&utm_content=CC2IQOS" onClick={this.updateStatus}>למה להישרף? תחממו ></a></Button>
      </>
    } else {
      return <>
        {(window.innerWidth <= 768)
          ? <ShareButton onShareComplete={() => this.handleShareComplete()}>הזמינו חבר ושחקו שוב ></ShareButton>
          : <Button><Link to="/gamestart">שחקו שוב ></Link></Button>
        }

        <p className={style.restrictionText}>* מעשן בגיר 21+ שביקש לקבל מידע</p>
        <Button color="#4ED2D3"><a target="_blank" rel="noreferrer" href="https://il.iqos.com/he/products/benefits?age_verified=1&utm_source=CC_MRL&utm_medium=EM&utm_campaign=CC_MRL_FUSION_JUL21&utm_content=CC2IQOS" onClick={this.updateStatus}>למה להישרף? תחממו ></a></Button>
      </>
    }
  }

  render () {
    let {referrerCode} = Storage.user;
    if ( typeof referrerCode !== 'undefined' && (referrerCode === 'hostesses' || referrerCode.match(/group/i) !== null) ) {
      return (
          <Screen loadingAnimation={this.state.loading} name="result" background={this.props.background} backgroundTablet={this.props.backgroundTablet} backgroundDesktop={this.props.backgroundDesktop} wrapperMaxWidth={464}>
            <h1 className={style.title}>נשרף הזמן!</h1>
            <div className={style.scoreBox}>
              <p className={style.scoreBoxTitle}>התוצאה שלכם</p>
              <p className={style.scoreBoxNumber}>{Storage.user.score}</p>
            </div>

            {this.renderButtons()}

              <br/>
              <Button><a rel="noreferrer" href="/ingame">שחק שוב ></a></Button>

            <PolicyFooter className={style.footer}/>
          </Screen>
      )
    }else if(window.innerWidth <= 768){
      return (
          <Screen loadingAnimation={this.state.loading} name="result" background={this.props.background} backgroundTablet={this.props.backgroundTablet} backgroundDesktop={this.props.backgroundDesktop} wrapperMaxWidth={464}>
            <h1 className={style.title}>נשרף הזמן!</h1>
            <p className={style.subtitle}>רוצים לנסות שוב? שתפו חבר וקבלו הזדמנות נוספת להשתתף בפעילות</p>

            <div className={style.scoreBox}>
              <p className={style.scoreBoxTitle}>התוצאה שלכם</p>
              <p className={style.scoreBoxNumber}>{Storage.user.score}</p>
            </div>

            {this.renderButtons()}

            <PolicyFooter className={style.footer}/>
          </Screen>
      )
    }else{
      return (
          <Screen loadingAnimation={this.state.loading} name="result" background={this.props.background} backgroundTablet={this.props.backgroundTablet} backgroundDesktop={this.props.backgroundDesktop} wrapperMaxWidth={464}>
            <h1 className={style.title}>נשרף הזמן!</h1>
            <p className={style.subtitle}>רוצים לנסות שוב? קבלו הזדמנות נוספת להשתתף בפעילות</p>

            <div className={style.scoreBox}>
              <p className={style.scoreBoxTitle}>התוצאה שלכם</p>
              <p className={style.scoreBoxNumber}>{Storage.user.score}</p>
            </div>

            {this.renderButtons()}

            <PolicyFooter className={style.footer}/>
          </Screen>
      )
    }


  }
}

export default withRouter(ResultScreen)
