import React from 'react'
import Screen from '../../Screen/Screen'
import Input from '../../Input/Input'
import Select from '../../Select/Select'
import Button from '../../Button/Button'
import PolicyFooter from '../../PolicyFooter/PolicyFooter'
import style from './UserInfoScreen.module.css'
import Date from "../../Date/Date"
import FormValidator from "../../../FormValidator"
import { withRouter } from 'react-router-dom'
import Storage from "../../../storage";

class UserInfoScreen extends React.Component {
  constructor(props) {
    super(props);
    this.brandsList = [
      "CAMEL",
      "CANADIAN",
      "CHESTERFIELD",
      "DAVIDOFF",
      "DOMINGO",
      "DRUM",
      "EUROPA",
      "EVE",
      "GAULOISES",
      "GLAMOUR",
      "GOLDEN VIRGINIA",
      "GOLF",
      "HEETS",
      "INFINITY",
      "KENT",
      "L&M",
      "LDS",
      "LUCKY STRIKE",
      "MARLBORO",
      "MONTANA",
      "MORE",
      "MUSTANG",
      "N.Y.C.",
      "NATURAL AMERICAN SPIRIT",
      "NELSON",
      "NEXT",
      "NOBLESSE",
      "PALL MALL",
      "PARLIAMENT",
      "REVAMP",
      "ROTHMANS",
      "ROYAL",
      "SHERATON",
      "SOLARIS",
      "TIME",
      "VOGUE",
      "WALL STREET",
      "WINSTON"
    ];

    this.history = props.history;

    this.state = {
      email: '',
      phone: '',
      birthDate: '1998-01-01',
      gender: '',
      brand: '',
      errors: {
        email: '',
        phone: '',
        birthDate: '',
        gender: '',
        brand: '',
      }
    }
  }

  handleChange (e) {
    const {name, value} = e.currentTarget;
    const formValidator = new FormValidator();
    const errors = this.state.errors;

    if ( value.length === 0 ) {
      errors[name] = formValidator.errorMessages.empty;
      this.setState({errors, [name]: value});
      return;
    }

    if ( formValidator.validate(value, 'html') ) {
      errors[name] = formValidator.errorMessages.invalid;
      this.setState({errors, [name]: value});
      return;
    }

    if ( name === 'email' && formValidator.validate(value, 'email') == null ) {
      errors[name] = formValidator.errorMessages.invalid;
      this.setState({errors, [name]: value});
      return;
    }

    if ( name === 'phone' && formValidator.validate(value, 'phone') == null ) {
      errors[name] = formValidator.errorMessages.invalid;
      this.setState({errors, [name]: value});
      return;
    }

    errors[name] = '';
    this.setState({errors, [name]: value});
  }

  isFormValid () {
    let valid = true;

    Object.values(this.state.errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );

    [this.state.email, this.state.phone, this.state.gender, this.state.brand, this.state.birthDate].forEach( value => value.length === 0 && (valid = false) );

    return valid;
  }

  handleSubmit (e) {
    e.preventDefault();

    localStorage.setItem('form_sent', '1');

    const fd = new FormData(e.target);
    Storage.write(fd);
    this.history.push('/score');
  }

  render () {
      return (
          <Screen name="user-info" background={this.props.background} backgroundTablet={this.props.backgroundTablet}
                  backgroundDesktop={this.props.backgroundDesktop}>
            <h1 className={style.title}>חושבים שהגעתם לטופ?</h1>
            <p className={style.subtitle}>מלאו את הפרטים ואולי תכנסו לרשימת המובילים שיכולים לזכות</p>
            {
              (window.innerWidth < 1024)
                  ? <p className={style.vipText}><span className="blue-text">בחוויה אקסקלוסיבית </span> <br/> בת 3 ימים
                    באילת </p>
                  : <p className={style.vipText}><span className="blue-text">בחוויה אקסקלוסיבית </span> <br/> בת 3 ימים
                    באילת </p>
            }

            <form onSubmit={e => this.handleSubmit(e)} className={style.form}>
              <Input onChange={e => this.handleChange(e)} value={this.state.email}
                     errorMessage={this.state.errors.email} name="email" type="email" placeholder="דוא״ל" required/>
              <Input onChange={e => this.handleChange(e)} value={this.state.phone}
                     errorMessage={this.state.errors.phone} name="phone" type="text" placeholder="טלפון" required/>
              <Date onChange={e => this.handleChange(e)} errorMessage={this.state.errors.birthDate}
                    value={this.state.birthDate} name="birthDate" placeholder="תאריך לידה" required/>
              <Select errorMessage={this.state.errors.gender} onChange={e => this.handleChange(e)}
                      value={this.state.gender} name="gender" placeholder="מגדר">
                <option value="Female">נקבה</option>
                <option value="Male">זכר</option>
              </Select>
              <Select errorMessage={this.state.errors.brand} onChange={e => this.handleChange(e)}
                      value={this.state.brand} name="brand" placeholder="מה המותג שלך?">
                {this.brandsList.map((brand, index) => <option key={index} value={brand}>{brand}</option>)}
              </Select>
              <Button disabled={!this.isFormValid()} className={style.submitButton}>שליחה והרשמה</Button>
            </form>

            <PolicyFooter/>
          </Screen>
      )
  }

}

export default withRouter(UserInfoScreen)
