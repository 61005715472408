import React from 'react'
import Popup from "../Popup/Popup";
import style from './AgePopup.module.css'
import Button from "../Button/Button";

let AgePopup = ({opened, onConfirm}, ref) => {
  return (
    <Popup opened={opened} ref={ref}>
      <div className={style.blurredBlock}>
        <p>אני מעשן מעל גיל 21 ומבקש לקבל מידע וחומר פרסומי לגבי המוצרים כהגדרתם להלן</p>
        <p> אני מוסר מרצוני לפיליפ מוריס בע”מ, לגופים הקשורים למוצרים שמייבאת, שמפיצה או שמשווקת ו/או תייבא תפיץ או תשווק פיליפ מוריס בע”מ (כגון, מוצרי עישון, טבק, מוצרים אחרים שבהם ניקוטין ומוצרים נלווים) (“המוצרים”) ולמחזיק מטעמם את הפרטים דלעיל, על מנת שפיליפ מוריס בע”מ והגופים האמורים לעיל יעבירו לי מידע ו/או חומר פרסומי על המוצרים או כל מידע ו/או חומר פרסומי אחר מטעם פיליפ מוריס בע”מ (“המידע והחומר הפרסומי”).</p>
        <p> אני מאשר לפיליפ מוריס בע”מ, לגופים הקשורים למוצרים ולמחזיק מטעמם, כי המידע והחומר הפרסומי יועברו אליי בכל מדיה שיווקית ובכל אמצעי בו תפעל פיליפ מוריס בע”מ, לרבות, בכפוף לחוק, באמצעות דואר אלקטרוני, SMS, פקס ומערכת חיוג אוטומטי.</p>
        <Button onClick={onConfirm}>אישור</Button>
      </div>
    </Popup>
  )
}

AgePopup = React.forwardRef(AgePopup);
export default AgePopup;
