import {
  Switch,
  Route,
  Redirect,
  withRouter
} from "react-router-dom";
import FirstScreen from "./components/routes/FirstScreen/FirstScreen";
import ThirdScreen from "./components/routes/ThirdScreen/ThirdScreen";
import GameScreen from "./components/routes/GameScreen/GameScreen";
import UserInfoScreen from "./components/routes/UserInfoScreen/UserInfoScreen";
import ResultScreen from "./components/routes/ResultScreen/ResultScreen";
import Storage from "./storage";
import {AnimatePresence} from "framer-motion";

import src1 from './assets/images/backgrounds/1.png';
import src2 from './assets/images/backgrounds/3-new.jpg';
import src3 from './assets/images/backgrounds/4.png';
import src4 from './assets/images/backgrounds/8.png';

// tablet
import src1Tablet from './assets/images/backgrounds/1-tablet.png';
import src2Tablet from './assets/images/backgrounds/3-tablet.png';
import src3Tablet from './assets/images/backgrounds/4-tablet.png';

// desktop
import src1Desktop from './assets/images/backgrounds/1-desktop.png';
import src3Desktop from './assets/images/backgrounds/3-desktop.png';
import src4Desktop from './assets/images/backgrounds/4-desktop.png';
import {Component} from "react";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: new URLSearchParams(window.location.search)
    }
  }

  componentDidMount() {

    if ( this.state.params.get('host_ref') ) {
      Storage.set('referrerCode', this.state.params.get('host_ref'));
    } else if ( window.location.pathname === '/sms' ) {
      Storage.set('referrerCode', 'sms')
    } else if ( window.location.pathname === '/hostesses' ) {
      Storage.set('referrerCode', 'hostesses')
    } else if ( window.location.pathname === '/email' ) {
      Storage.set('referrerCode', 'email')
    } else if ( window.location.pathname === '/' || window.location.pathname === '/share' ) {
      Storage.set('referrerCode', "");
    }
  }

  render() {
    const location = window.location;


    let sended_form = localStorage.getItem('form_sent');

    if (['/sms', '/email', '/hostesses', '/share', '/sms/', '/email/', '/hostesses/', '/share/'].indexOf(window.location.pathname) !== -1) {
      Storage.set('joinedVia', window.location.pathname.replace('/', '').replace('/', ''));
    }

    if (window.location.pathname === '/') {
      Storage.set('joinedVia', '');
    }

    return (
      <AnimatePresence>
        <Switch location={window.location} key={window.location.pathname}>

          <Route path={["/email", "/sms"]}>
            <ThirdScreen background={src2} backgroundTablet={src2Tablet} backgroundDesktop={src3Desktop}/>
          </Route>
          <Route path={["/hostesses/gamestart"]}>
            <ThirdScreen background={src2} backgroundTablet={src2Tablet} backgroundDesktop={src3Desktop}/>
          </Route>
          <Route path={["/email/gamestart"]}>
            <ThirdScreen background={src2} backgroundTablet={src2Tablet} backgroundDesktop={src3Desktop}/>
          </Route>
          <Route path={["/sms/gamestart"]}>
            <ThirdScreen background={src2} backgroundTablet={src2Tablet} backgroundDesktop={src3Desktop}/>
          </Route>
          <Route path={["/gamestart"]}>
            <ThirdScreen background={src2} backgroundTablet={src2Tablet} backgroundDesktop={src3Desktop}/>
          </Route>

          <Route path="/ingame">
            <GameScreen background={src3} backgroundTablet={src3Tablet} backgroundDesktop={src4Desktop}/>
          </Route>

          <Route path="/endform">
            {(sended_form !== '1' && Storage.user.hasOwnProperty('joinedVia') && (Storage.user.joinedVia === 'sms' || Storage.user.joinedVia === 'email'))
                ? <Redirect to="/score"/>
                : <UserInfoScreen background={src1} backgroundTablet={src1Tablet} backgroundDesktop={src1Desktop}/>
            }
            {(sended_form === '1')
              ? <Redirect to="/score"/>
              : <UserInfoScreen background={src1} backgroundTablet={src1Tablet} backgroundDesktop={src1Desktop}/>
            }
          </Route>

          <Route path="/score">
            <ResultScreen background={src4} backgroundTablet={src1Tablet} backgroundDesktop={src1Desktop}/>
          </Route>

          <Route exact path={["/hostesses", "/share", "/"]}>
            <FirstScreen background={src1} backgroundTablet={src1Tablet} backgroundDesktop={src1Desktop}/>
          </Route>

        </Switch>
      </AnimatePresence>
    );
  }
}

export default withRouter(App);
