import React from 'react'
import style from '../Input/Input.module.css'
import selectStyle from './Select.module.css'

const Select = ({ onChange, name, children, className, placeholder = '', required = '', errorMessage }) => {
  let classes = [className, style.input].join(' ');

  let defaultOption = placeholder
    ? <option defaultValue="" disabled selected>{(required ? '*' : '') + placeholder}</option>
    : '';

  return (
    <label className={[style.label, selectStyle.label].join(' ')}>
      <select onChange={onChange} required={required} className={classes} name={name}>
        {defaultOption}
        {children}
      </select>
      <span></span>
      { (errorMessage) && <p className={style.errorMessage}>{errorMessage}</p> }
    </label>
  )
}

export default Select
