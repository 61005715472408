export async function searchUser(passport, firstName, lastName, referrerCode) {
  let params = {
    createUserIfNotFound: false,
    createCampaignMember: false,
    updateCampaignMember: false,
    updateUserIfFound: false,
    numberOfParticipationsAllowed: 999,
    validateId: true,
    GGID_Verified: false,
    GGID_VerifiedBy: "REF",
    allowUnlimitedParticipation: true,
    incrementParticipationNumber: false,
    searchUser: true,
    GGID: passport,
    verifySFID: false,
    previousStatusAllowed: true,
    newStatus: "4- Started Game",
    firstName: firstName,
    lastName: lastName,
    referrerCode: referrerCode,
    userSFID_ForMember: '',
    campaignId: process.env.REACT_APP_CMS_API_CAMPAIGN_ID,
    content_type: "application/json"
  };

  let resp = await fetch(process.env.REACT_APP_CMS_API_URL, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'API-TOKEN': `${process.env.REACT_APP_CMS_API_TOKEN}`
    }
  });

  let data = await resp.json();
  return data;
}

export async function updateUser(options) {

  let params = {
    campaignId: process.env.REACT_APP_CMS_API_CAMPAIGN_ID,
    createUserIfNotFound: true,
    createCampaignMember: true,
    updateCampaignMember: true,
    updateUserIfFound: true,
    numberOfParticipationsAllowed: 999,
    validateId: false,
    GGID_Verified: true,
    GGID_VerifiedBy: options.GGID_VerifiedBy,
    allowUnlimitedParticipation: true,
    incrementParticipationNumber: true,
    searchUser: false,
    userSFID_ForMember: options.userSFID_ForMember,
    userSFID: options.userSFID,
    verifySFID: false,
    previousStatusAllowed: false,
    newStatus: "7- Finished_Fill Out Form",
    firstName: options.firstName,
    lastName: options.lastName,
    email: options.email,
    mobile: options.phone,
    dateOfBirth: options.birthDate,
    gender: options.gender,
    mainBrand: options.brand,
    termsAcceptance: true,
    mobileOptIn: true,
    emailOptIn: true,
    channel: "Landing Page",
    SF_CurrentStatus: options.userFound ? "" : "4- Started Game(Check1)",
    campaignParam1: "",
    gameDate: new Date(),
    gameDuration: "4.0",
    gameStartTime: null,
    gameEndTime: null,
    gameScore: options.score,
    userType: options.userType,
    campaignRespondedStatus: options.userFound ? "4- Started Game(Check2)" : "",
    Number_Of_Times_Participated__c: options.numberOfTimesParticipated,
    content_type: "application/json"
  }

  if(typeof options.passport !== 'undefined') {
    params.GGID = options.passport;
  }

  if( options.referrerCode !== null && options.referrerCode !== 'null' && options.referrerCode !== 'email' && options.referrerCode !== 'sms' ) {
    params.referrerCode = options.referrerCode;
  } else {
    params.referrerCode = '';
  }

  if( options.referrerSFID !== null && options.referrerSFID !== 'null' ) {
    params.referrerSFID = options.referrerSFID;
  }

  if( options.memberSFID !== '0' && options.memberSFID !== '' ) {
    params.createCampaignMember = false;
    params.memberSFID = options.memberSFID;
  }

  if( options.joinedVia === 'sms' || options.joinedVia === 'email' ) {
    params.newStatus = '6- Finished SMS';
  }

  let resp = await fetch(process.env.REACT_APP_CMS_API_URL, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'API-TOKEN': `${process.env.REACT_APP_CMS_API_TOKEN}`
    }
  });

  let data = await resp.json();
  return data;
}

export async function getUserBySID(sfid) {
  sfid = atob(sfid);
  let params = {
    campaignId: process.env.REACT_APP_CMS_API_CAMPAIGN_ID,
    createUserIfNotFound: false,
    createCampaignMember: false,
    updateCampaignMember: false,
    updateUserIfFound: false,
    validateId: false,
    GGID_Verified: true,
    GGID_VerifiedBy: 'SF',
    allowUnlimitedParticipation: true,
    numberOfParticipationsAllowed: 999,
    searchUser: true,
    userSFID: '',
    userSFID_ForMember: sfid,
    verifySFID: true,
    previousStatusAllowed: true,
    newStatus: "3- clicked",
    channel: "Landing Page",
    content_type: "application/json"
  }

  let resp = await fetch(process.env.REACT_APP_CMS_API_URL, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'API-TOKEN': `${process.env.REACT_APP_CMS_API_TOKEN}`
    }
  });

  let data = await resp.json();
  return data;
}

export async function updateStatus(options, status = '3- clicked') {
  let params = {
    campaignId: process.env.REACT_APP_CMS_API_CAMPAIGN_ID,
    createUserIfNotFound: false,
    createCampaignMember: false,
    updateCampaignMember: true,
    updateUserIfFound: false,
    validateId: false,
    GGID_Verified: true,
    GGID_VerifiedBy: 'SF',
    allowUnlimitedParticipation: true,
    searchUser: false,
    userSFID: options.userSFID,
    userSFID_ForMember: options.userSFID_ForMember,
    memberSFID: options.memberSFID,
    userType: options.userType,
    campaignRespondedStatus: options.campaignRespondedStatus,
    verifySFID: true,
    previousStatusAllowed: true,
    newStatus: status,
    channel: "Landing Page",
    content_type: "application/json"
  }

  if( options.SF_CurrentStatus ) {
    let currentStatus = parseInt(options.SF_CurrentStatus.charAt(0)),
        changeStatusTo = parseInt(status.charAt(0));

    if (currentStatus >= changeStatusTo) {
      //params.newStatus = options.SF_CurrentStatus;
      return options;
    }
  }

  let resp = await fetch(process.env.REACT_APP_CMS_API_URL, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'API-TOKEN': `${process.env.REACT_APP_CMS_API_TOKEN}`
    }
  });

  let data = await resp.json();
  return data;
}